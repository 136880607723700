<template>
    <v-sheet height="370px">
        <v-row>
            <v-col cols="12" md="6">
                <v-img :src="`https://bonsai.demoing.co.za/assets/images/${src}`"
                       class="white--text align-end"
                       gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                       height="370px">
                </v-img>
            </v-col>
            <v-col>
                <v-card-title >
                    <slot name="title"></slot>
                </v-card-title>
                <v-card-subtitle>
                    <slot name="text"></slot>
                </v-card-subtitle>
            </v-col>
        </v-row>
    </v-sheet>
</template>
<script>
    export default {
        name: 'welcome-carousel-card',
        components: {},
        props: {
            src: String
        },
        data: () => {
            return {               
            };
        },
    };
</script>