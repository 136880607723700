<template>
    <div>
        <v-card class="card-shadow">
            <v-carousel height="370">
                <v-carousel-item>
                    <welcome-carousel-card src="home_banner_1.jpg">
                        <template v-slot:title>
                            <span>Welcome to &nbsp;<span class="primary--text underline--magical">Bonsai</span></span>
                        </template>
                        <template v-slot:text>
                            We are passionate about your financial planning. Let us help you manage your personal
                            finances such as insurance, investments, medical aid, estate planning — side by side with a
                            financial professional.
                        </template>
                    </welcome-carousel-card>
                </v-carousel-item>
                <v-carousel-item>
                    <welcome-carousel-card src="home_banner_2.jpg">
                        <template v-slot:title>
                            <span>Make use of our Money Management platform</span>
                        </template>
                        <template v-slot:text>
                            Our all-in-one platform is the only South African service that makes it easy for you to get
                            a financial roadmap, set up and manage your financial products, track your financial progress and
                            act when you need to.
                        </template>
                    </welcome-carousel-card>
                </v-carousel-item>
                <v-carousel-item>
                    <welcome-carousel-card src="home_banner_3.jpg">
                        <template v-slot:title>
                            <span> We’re independent</span>
                        </template>
                        <template v-slot:text>
                            We represent a wide variety of South African Financial Service Providers to ensure that
                            you receive unbiased financial advice from an experienced financial professional. Your appointed
                            professional is required to keep your best interests in mind at all times—no sketchy sales tactics,
                            no quotas, and no ulterior motives.
                        </template>
                    </welcome-carousel-card>
                </v-carousel-item>
                <v-carousel-item>
                    <welcome-carousel-card src="home_banner_4.jpg">
                        <template v-slot:title>
                            <span><span><span class="underline--magical">Financial wellness</span> is essential. Now more than ever.</span></span>
                        </template>
                        <template v-slot:text>
                            Did you know that the #1 source of stress among South Africans aged 21 – 75 is
                            financial stress? At Bonsai, we see this problem as our guiding light – we want to help South
                            Africans take hold of their finances and forge true financial wellness by making the right decisions
                            regarding the portfolio.
                        </template>
                    </welcome-carousel-card>
                   
                </v-carousel-item>
            </v-carousel>
        </v-card>
        <v-card class="card-shadow mt-5 mb-5">
            <v-container>
                <v-row>
                    <v-col cols="12" md="8" lg="8">
                        <v-card-title>
                            <span>Get Your Money Right. Get <span class="underline--magical">rewarded</span>.</span>
                        </v-card-title>
                        <v-card-text class="pb-0">
                            We are not only committed to helping you achieve financial wellness, but we also believe
                            in rewarding you for being a valued customer. Receive monthly cashback on every policy that we
                            help you manage. Your cashback can be spent anywhere on the internet using a free virtual card,
                            or even better, you will earn 7.00% interest per year on all of your unspent cashbacks.
                        </v-card-text>
                        <v-card-text>
                            <!--<v-btn color="primary" small class="mr-2">Get Started</v-btn>-->
                            <v-btn color="primary" small outlined class="normal-caps">Watch Video</v-btn>
                        </v-card-text>
                    </v-col>
                    <v-col cols="12" md="4" lg="4">
                        <v-img :src="`https://bonsai.demoing.co.za/assets/images/home_get_rewarded.jpg`"
                               class="white--text align-end"
                               cover
                               max-height="200"></v-img>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>

        <v-row>
            <v-col cols="12" md="6" >
                <statements-card icon="hbavnglv">
                    <template v-slot:title>
                        A modern take on your money.
                    </template>
                    <template v-slot:text>
                        We are a different kind of financial advisory company whose goal is to help people get
                        their money right. Our products are built around our members—so that they have the tools they
                        need to take control of their financial futures.
                    </template>
                </statements-card>
            </v-col>
            <v-col cols="12" md="6">
                <statements-card icon="hbavnglv">
                    <template v-slot:title>
                        Our member’s matter.
                    </template>
                    <template v-slot:text>
                        Our #1 priority is putting members first—it’s even one of our company values. If our
                        members are successful, we’re successful. That’s why we offer forward-thinking and exclusive
                        member benefits at no cost.
                    </template>
                </statements-card>
            </v-col>
            <v-col cols="12" md="6">
                <statements-card icon="hbavnglv">
                    <template v-slot:title>
                        Superior portfolio management
                    </template>
                    <template v-slot:text>
                        While we offer many of the same products and services as traditional independent
                        brokerages, we are not a conventional independent brokerage. We hold our services to high
                        regulatory and compliance standards and deliver our services in a digital-first medium—our
                        company protects our members’ financial portfolio and personal information whilst providing
                        excellent service.
                    </template>
                </statements-card>
            </v-col>
            <v-col cols="12" md="6">
                <statements-card icon="hbavnglv">
                    <template v-slot:title>
                        We also work with businesses
                    </template>
                    <template v-slot:text>
                        Bonsai provides a fully integrated range of business solutions. We are here for you and
                        your business from employee benefits, employee healthcare consulting, commercial risk, and
                        much more. We have designed our services to meet your organization’s needs comprehensively.
                    </template>
                </statements-card>
            </v-col>
        </v-row>
        <v-card class="card-shadow mt-10">
            <v-container>
                <v-row>
                    <v-col cols="12" md="12" lg="8">
                        <v-card-title> Easy process, expert support </v-card-title>
                        <v-card-text class="pb-0">
                            Bonsai provides free quotes and policy management tailored to your needs with support from
                            licensed agents, helping you get financial planning on track fast so you can get on with life.
                        </v-card-text>
                    </v-col>
                    <v-col cols="12" md="12" lg="4">
                        <v-img :src="`https://images.unsplash.com/photo-1539678786826-79e8b51b457a`"
                               class="white--text align-end"
                               cover
                               max-height="200"></v-img>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
        <v-card class="card-shadow mt-10">
            <v-container>
                <v-card-title>
                    Companies we support
                </v-card-title>
                <v-row>
                    <v-col cols="12">
                        <v-slide-group v-model="model"
                                       class="pa-4"
                                       show-arrows>
                            <v-slide-item v-for="n in 15"
                                          :key="n"
                                          v-slot="{ active }">
                                <v-card :color="active ? 'primary' : 'grey lighten-1'"
                                        class="ma-4"
                                        height="100"
                                        width="150">
                                </v-card>
                            </v-slide-item>
                        </v-slide-group>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
    </div>
</template>

<script>
    import StatementsCard from './home/statements-card.vue'
    import WelcomeCarouselCard from './home/welcome-carousel-card.vue'
    export default {
        name: "Home",

        components: {
            StatementsCard,
            WelcomeCarouselCard
        },
        data: () => {
            return {
                model: null,
                panel: [0, 1, 2, 3],
                tab1: 0,
                tab2: 0,
                items: ["Appetizers", "Entrees", "Deserts", "Cocktails"],
                colors: [
                    "red",
                    "green",
                    "blue",
                    "purple",
                    "yellow",
                    "pink",
                    "orange",
                    "amber",
                ],
            };
        },
    };
</script>
